import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Slider from "react-slick";

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Download } from "react-feather";
import VimeoEmbed from "../../components/vimeo-embed";

const Solutions = () => {

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        pauseOnFocus: true,
        cssEase: "linear",
        fade: true
    };


    const data = useStaticQuery(graphql`
    query {
        appPhone: file(relativePath: { eq: "solutions/app_phone_01.png" }) {
            childImageSharp {
                fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid_noBase64
                }
            }
        },
        appIpad: file(relativePath: { eq: "solutions/app_ipad.png" }) {
            childImageSharp {
                fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid_noBase64
                }
            }
        },
        consulting: file(relativePath: { eq: "solutions/consulting.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 350) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        },
        iconCentralWhite: file(relativePath: { eq: "solutions/icon_central_white.png" }) {
            childImageSharp {
                fixed(width: 80, height: 80) {
                ...GatsbyImageSharpFixed
                }
            }
        },
        iconApp: file(relativePath: { eq: "solutions/icon_app.png" }) {
            childImageSharp {
                fixed(width: 45, height: 80) {
                ...GatsbyImageSharpFixed
                }
            }
        },
        iconConsulting: file(relativePath: { eq: "solutions/icon_consulting.png" }) {
            childImageSharp {
                fixed(width: 73, height: 61) {
                ...GatsbyImageSharpFixed
                }
            }
        },
        slide1: file(relativePath: { eq: "solutions/01.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        },
        slide2: file(relativePath: { eq: "solutions/02.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        },
        slide3: file(relativePath: { eq: "solutions/03.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        },
        slide4: file(relativePath: { eq: "solutions/04.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        },
        slide5: file(relativePath: { eq: "solutions/05.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        },
 
    }`
    )

    const solutionsText = () => {
        return (
            <div className="slide-item__content">
                <h1 className='h2'>Mobile-First Platform</h1>
                <div className='rose-banner-title'>
                    Now including ROSE our AI assistant.
                </div>
                <h4 className='solutions-slide-subtitle'>Restorativ has designed its solutions to meet the continuum of RJ needs, ranging from the Restorativ app, which provides a simplified framework to Restorativ Central, which offers a comprehensive RJ technology solution, all without compromising rigor. Both ends of this spectrum may require additional support implementing restorative practices; Restorativ provides this via its consulting team.</h4>
            </div>
        );
    }

    return (
        <Layout>
            <SEO title="Solutions" />
            <div className='d-block d-md-none solutions-intro-text-mobile'>
                {solutionsText()}
            </div>
            <Slider {...settings}>
                <div className='slide-item slide-item--about'>
                    <Img fluid={data.slide1.childImageSharp.fluid} />
                    <div className="slide-item__wrapper">
                        {solutionsText()}
                        <h2 className='slide-item__base-title'>Schools</h2>
                    </div>
                </div>
                <div className='slide-item slide-item--about'>
                    <Img fluid={data.slide2.childImageSharp.fluid} />
                    <div className="slide-item__wrapper">
                        {solutionsText()}
                        <h2 className='slide-item__base-title'>Companies</h2>
                    </div>
                </div>
                <div className='slide-item slide-item--about'>
                    <Img fluid={data.slide3.childImageSharp.fluid} />
                    <div className="slide-item__wrapper">
                        {solutionsText()}
                        <h2 className='slide-item__base-title'>Online networks</h2>
                    </div>
                </div>
                <div className='slide-item slide-item--about'>
                    <Img fluid={data.slide4.childImageSharp.fluid} />
                    <div className="slide-item__wrapper">
                        {solutionsText()}
                        <h2 className='slide-item__base-title'>Criminal justice</h2>
                    </div>
                </div>
                <div className='slide-item slide-item--about'>
                    <Img fluid={data.slide4.childImageSharp.fluid} />
                    <div className="slide-item__wrapper">
                        {solutionsText()}
                        <h2 className='slide-item__base-title'>RJ Practices</h2>
                    </div>
                </div>
            </Slider>

            <div className="section-accent-alt">
              <div className="content-wrapper container-fluid">
                <h2 className="text-center mb-4 pb-0">Solutions Overview</h2>
                <div className="solutions-video">
                  <VimeoEmbed video_id="900856618" />
                </div>
              </div>
            </div>

            <div id="solution-app" className="section-accent">
                <div className="content-wrapper solution__app">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-5 order-1 order-md-first">
                                <div className="solution__app__img">
                                    <Img fluid={data.appPhone.childImageSharp.fluid} />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="solution__heading">
                                    <div className="solution__heading__img">
                                        <Img fixed={data.iconApp.childImageSharp.fixed} />
                                    </div>
                                    <div className="solution__heading__title">
                                        <h4 className='mb-0'>Restorativ app<br/>
                                        A simplified framework</h4>
                                    </div>
                                </div>
                                <p className='mb-4'>A fully automated solution that can be accessed from any mobile device and only requires one administrator to provide an RJ solution for potentially thousands of users.</p>
                                <ul className='tag-list'>
                                    <li>Training management</li>
                                    <li>Social posting</li>
                                    <li>Case data</li>
                                    <li>Time management</li>
                                    <li>Harmed / harmer facilitation</li>
                                    <li>Scheduling</li>
                                    <li>Document management</li>
                                    <li>Note management</li>
                                    <li>Fully-virtual RJ solution</li>
                                    <li>Empathy assessment</li>
                                </ul>
                                <a className="btn btn-muted download-guide" href="/downloads/Restorativ_How to_App Guide_Facilitators.pdf"><Download size="20" />How to App Guide</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="solution-central" className="section-white">
                <div className="content-wrapper solution__app">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="solution__heading solution__heading--alt">
                                    <div className="solution__heading__img solution__heading__img--alt">
                                        <Img fixed={data.iconCentralWhite.childImageSharp.fixed} />
                                    </div>
                                    <div className="solution__heading__title">
                                        <h4 className='mb-0'>Restorativ Central <br/>
                                        A comprehensive RJ technology solution</h4>
                                    </div>
                                </div>
                                <p className='mb-4'>A comprehensive solution that is designed to manage a complex RJ cases from opening to closing. The application is accessible from any device and is profile based (Circle Keeper, Responsible Party, Affected Party, Case Facilitators, Community Member) with end to end management of each case and the resulting data and restoring outcomes. </p>
                                <ul className='tag-list'>
                                    <li>Training management</li>
                                    <li>AI Support</li>
                                    <li>Referral Management</li>
                                    <li>CRM</li>
                                    <li>Case Management</li>
                                    <li>Document Management</li>
                                    <li>RJ Facilitator Management</li>
                                    <li>Real-time Reporting</li>
                                </ul>
                            </div>
                            <div className="col-md-5">
                                <div className="solution__app__img mt-3 mt-md-4 pt-md-2">
                                    <Img fluid={data.appIpad.childImageSharp.fluid} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="solution-consulting" className="section-primary">
                <div className="content-wrapper solution__app">
                    <div className="container-fluid">
                        <div className="row py-3">
                            <div className="col-md-6 order-1 order-md-first">
                                <div className="solution__app__img solution__app__img--wide">
                                    <Img fluid={data.consulting.childImageSharp.fluid} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="solution__heading mt-0">
                                    <div className="solution__heading__img">
                                        <Img fixed={data.iconConsulting.childImageSharp.fixed} />
                                    </div>
                                    <div className="solution__heading__title">
                                        <h4 className='mb-0'>Restorativ <br /> Consulting</h4>
                                    </div>
                                </div>
                                <p className='mb-4'>We understand that implementing a technology solution can be a daunting and often complex task. Restorativ Consulting provides online and in person implementation support if needed.</p>
                                <ul className='tag-list mb-3'>
                                    <li>Online support</li>
                                    <li>In person consultation</li>
                                    <li>Organizational readiness assessment</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Solutions
